import React, { createContext } from 'react';
import  { useMutation } from '@apollo/client';
import { SEND_INVITE, SEND_PASSWORD_RESET_EMAIL, RESET_PASSWORD, ACCEPT_INVITATION } from './queries';

import useAPIErrorNotifier from '../../../hooks/useAPIErrorNotifier';
import useAPISuccessNotifier from '../../../hooks/useAPISuccessNotifier';

const PasswordContext = createContext(null)
export default PasswordContext;

export const withPasswordContext = (Component) => (props) => {
	const [initiatePasswordReset, { data: initiateResetPasswordData, error: initiateResetPasswordError }] = useMutation(SEND_PASSWORD_RESET_EMAIL);
	const [resetPassword, { data: resetPasswordData, error: resetPasswordError }] = useMutation(RESET_PASSWORD);
	const [sendInvite, { data: sendInviteData, error: sendInviteError }] = useMutation(SEND_INVITE);
	const [acceptInvitation, { data: acceptInvitationData, error: acceptInvitationError, loading: acceptingInvite }] = useMutation(ACCEPT_INVITATION);

	useAPIErrorNotifier(initiateResetPasswordError, 'Reset Password Email Error')
  useAPISuccessNotifier(initiateResetPasswordData?.initiatePasswordReset, 'Initiate Password Reset')

	useAPIErrorNotifier(resetPasswordError, 'Error in Reset Password')
  useAPISuccessNotifier(resetPasswordData?.changeAdminPassword, 'Send Admin Invitation')

	useAPIErrorNotifier(sendInviteError, 'Error in sending invite')
  useAPISuccessNotifier(sendInviteData?.sendInvite, 'Send Admin Invitation')

	useAPIErrorNotifier(acceptInvitationError, 'Error in Accepting Invite')
  // useAPISuccessNotifier(acceptInvitationData?.acceptInvitation, 'Admin Invitation')

	return (
		<PasswordContext.Provider
			value={{
				initiatePasswordReset,
				resetPassword,
				sendInvite,
				acceptInvitation,
				acceptingInvite,
				acceptInvitationData,
				acceptInvitationError,
			}}
		>
			<Component {...props} />
		</PasswordContext.Provider>
	)
}