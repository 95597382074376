import { useSnackbar } from 'notistack';
import { useEffect} from 'react'
function useAPISuccessNotifier(message) {

  const { enqueueSnackbar } = useSnackbar()
  useEffect(() => {
    if(message)
    enqueueSnackbar(message, { variant: "Success" })
  }, [message, enqueueSnackbar])

}

export default useAPISuccessNotifier;