import React from 'react'
import { FormHelperText } from '@mui/material';

export default function ErrorMessage({error}) {
	return (
		<div>
			{error && <FormHelperText error={true}>{error}</FormHelperText>}
		</div>
	)
}
