import CircularProgress from '@mui/material/CircularProgress';
import React from 'react';

const Loading = ({size = 'large'}) => {
  return (
    <div className="flex center middle p-10">
      <CircularProgress size={size === 'large' ? '2rem' : '1rem'} />
    </div>
  );
};

export default Loading;