import React, { lazy, Suspense } from 'react';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import { createBrowserHistory } from 'history'
import Loading from "../components/Loading";
import ResetPassword from '../components/Admin/ResetPassword';
import { withPasswordContext } from '../contexts/apollo/password';
import EmailVerificationPage from '../components/UI/EmailVerificationPage';
import ForgotPassword from '../components/Admin/ForgotPassword';
import TraceHarvestReport from '../components/TraceHarvestReport'

const Admin = lazy(() => import(/* webpackChunkName: "Admin" */ "./Admin"));
const history = createBrowserHistory();

const AppRouter = () => {
  return (
    <Router history={history}>
      <main>
      <Suspense fallback={<Loading />} >
        <Switch>
          <Route path="/trace/harvestReport/:token" component={TraceHarvestReport} />
          <Route path='/resetPassword/:token' component={ResetPassword} />
          <Route path='/verifyEmail/:token' component={EmailVerificationPage} />
          <Route path='/forgotPassword' component={ForgotPassword} />
          <Route path="/" component={Admin} />
        </Switch>
      </Suspense>
      </main>
    </Router>
  );
}
export default withPasswordContext(AppRouter);