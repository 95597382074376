import React, { useEffect, useState } from 'react';
import "../../styles/internet-connection.css";

export function useNetworkConnection() {
  const [online, setOnline] = useState(window.navigator.onLine)

  const changeStatus = (value) => setOnline(value)

  const changeStatusTrue = () => changeStatus(true)
  const changeStatusFalse = () => changeStatus(false)

  useEffect(() => {
    window.addEventListener('online', changeStatusTrue);
    window.addEventListener('offline', changeStatusFalse);
    return () => {
      console.log('Remove network listeners')
      window.removeEventListener('online', changeStatusTrue)
      window.removeEventListener('offline', changeStatusFalse)
    }
  })

  return online
}

function NotConnected(props) {

  const online = useNetworkConnection()

  if (online) return null

  return (
    <div className='offline-ribbon flex center'>
      <div>
        You are not connected to internet.
      </div>
    </div>
  );
}

export default NotConnected;