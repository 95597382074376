import React, { useContext, useState } from 'react';
import Button from '@mui/material/Button';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import TextField from '@mui/material/TextField';
import CardHeader from '@mui/material/CardHeader';
import PasswordContext, { withPasswordContext } from '../../contexts/apollo/password';
import butter from '../../assets/images/butter.png'


function ForgotPassword() {
	const [email, setEmail] = useState('')
  const { initiatePasswordReset } = useContext(PasswordContext);

  const handleSubmit = (e) => {
		e.preventDefault();
		setEmail(e.target.value);
    return initiatePasswordReset({
      variables: {
				email
      }
    }).then(() => {
      setEmail("")
    })
  } 

  return (
    <div 
      style={{
        height: '100vh'
      }}
      className='bg-gradient-0 flex center middle'
    >
      <Card style={{minWidth: 400, background: 'white', padding: 20}} >
        <div className='flex center'>
          <img src={butter} alt='logo' width='150px' style={{objectFit: 'cover'}} />
        </div>
        <CardHeader title="Enter your email" style={{textAlign: 'center'}} />
        <CardContent>
          <form onSubmit={handleSubmit}>
					<div style={{marginBottom: 10}}>
              <TextField
                autoFocus
                fullWidth
                variant="outlined"
                label="Email"
                name="email"
                value={email}
								required={true}
								onChange={(e) => setEmail(e.target.value)}
              />
            </div>
            <div >
              <Button 
                variant="contained" 
                type="submit"
                color="primary"
                size='large'
                fullWidth
              >
                Proceed
              </Button>
            </div>
          </form>
          
        </CardContent>
      </Card>
    </div>
  );
}

export default withPasswordContext(ForgotPassword)