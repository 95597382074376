import { useSnackbar} from 'notistack';
import { useEffect} from 'react'

function useAPIErrorNotifier(error, message) {

  const { enqueueSnackbar } = useSnackbar()

  useEffect(() => {
    if(error)
    enqueueSnackbar(message, { variant: "error" })
  }, [error, enqueueSnackbar, message])

}

export default useAPIErrorNotifier;   