import React from 'react'
import { ApolloProvider } from '@apollo/client';  
import { createTheme, ThemeProvider } from "@mui/material/styles";
import { colors, CssBaseline } from "@mui/material";
import AppRouter from './routers';
import NotConnected from "./components/UI/NotConnected";
import apolloClient from './api/apolloClient'
import "./App.css";
import "./styles/main.css";
import "./styles/theme.css";

function App() {

  const theme = createTheme({
    typography: {
      fontFamily: 'Montserrat'
    },
    palette: {
      default: {
        light: '#000'
      },
      primary: {
        light: '#FDAA65',
        main: '#fc5a35',
        contrastText: '#fff'
      },
      secondary: {
        light: colors.white,
        main: "#f50057",
        dark: "#c51162",
        contrastText: "#fff",
      },
      background: {
        default: "#fff",
        paper: "#fff",
      },
    },
  });

  return (
    <div id='app'>
      <NotConnected />
      <ApolloProvider client={apolloClient} >
      <ThemeProvider theme={theme}>
        <CssBaseline />
        <AppRouter />
      </ThemeProvider>
      </ApolloProvider>
    </div>
  );
}

export default App;
