import React, { useContext, useState, useEffect } from 'react';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import PasswordContext from '../../contexts/apollo/password';
import butter from '../../assets/images/butter.png'
import { useHistory, useParams } from 'react-router';
import Loading from '../Loading';

function LoginAdmin() {
	//eslint-disable-next-line
	const [verified, setVerified] = useState(true)
	const [message, setMessage] = useState("")
	const { acceptInvitation, acceptingInvite, acceptInvitationData } = useContext(PasswordContext);
	const { token } = useParams();
	const history = useHistory()

	useEffect(() => {
		acceptInvitation({
			variables: {
				token
			}
		})
	}, [acceptInvitation, token])

	useEffect(() => {
		setVerified(Boolean(acceptInvitationData))
	}, [acceptInvitationData])

	useEffect(() => {
		if (acceptInvitationData) {
			setMessage(acceptInvitationData?.acceptInvitation?.message)
			setTimeout(() => {
				if (acceptInvitationData?.acceptInvitation?.code) {
					history.push(`/resetPassword/${token}`)
				}
			}, 2000)
		}
	}, [acceptInvitationData, history, token])

	return (
		<div
			style={{
				height: '100vh'
			}}
			className='bg-gradient-0'
		>
			<div className='flex center middle' style={{ height: '100vh' }}>
				<Card style={{ minWidth: 350, background: 'white' }} >
					<div className='flex center'>
						<img src={butter} alt='logo' width='150px' style={{ objectFit: 'cover' }} />
					</div>
					<CardContent>
						<div style={{ marginBottom: 10 }}>
							{acceptingInvite && <Loading />}
							{acceptInvitationData &&
								<div>
									<h2 style={{ textAlign: 'center' }} > {message} </h2>
								</div>
							}
						</div>
					</CardContent>
				</Card>
			</div>
		</div>
	);
}

export default LoginAdmin