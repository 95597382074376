import React, { useContext, useState } from 'react';
import Button from '@mui/material/Button';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardHeader from '@mui/material/CardHeader';
import PasswordContext, { withPasswordContext } from '../../contexts/apollo/password';
import butter from '../../assets/images/butter.png'
import PasswordInput from '../UI/Forms/PasswordInput';
import { useParams } from 'react-router';
import ErrorMessage from '../UI/ErrorMessage';
import { useHistory } from 'react-router';

function ResetPassword() {
  const { token } = useParams();
  const [newPassword, setNewPassword] = useState('')
  const [confirmedPassword, setConfirmedPassword] = useState('')
  const { resetPassword } = useContext(PasswordContext);
  const [error, setError] = useState(null)
  const history = useHistory();

  const handleSubmit = (e) => {

    e.preventDefault()
    if(newPassword !== confirmedPassword) {
      setError('Passwords do not match. ')
      return;
    }

    return resetPassword({
      variables: {
        input: {
          token,
          newPassword,
        }
      }
    }).then(() => {
      setConfirmedPassword("")
      setNewPassword("")
      setTimeout(() => {
        history.push('/')
      }, 5000);
    })
  } 

  return (
    <div 
      style={{
        height: '100vh'
      }}
      className='bg-gradient-0 flex center middle'
    >
      <Card style={{minWidth: 400, background: 'white', padding: 20}} >
        <div className='flex center'>
          <img src={butter} alt='logo' width='150px' style={{objectFit: 'cover'}} />
        </div>
        <CardHeader title="Enter New Password" style={{textAlign: 'center'}} />
        <CardContent>
          <form onSubmit={handleSubmit}>
            <div style={{marginBottom: 10,}}>
              <PasswordInput
                value={newPassword}
                label = "New Password"
                onChange = {(newPassword) => setNewPassword(newPassword)}
              />
            </div>
            <div style={{marginBottom: 10,}}>
              <PasswordInput
                defaultType='text'
                value={confirmedPassword}
                label = "Confirm Password"
                onChange = {(password) => setConfirmedPassword(password)}
              />
            </div>
            <ErrorMessage error={error} />
            <div >
              <Button 
                variant="contained" 
                type="submit"
                color="primary"
                size='large'
                fullWidth
              >
                Save
              </Button>
            </div>
          </form>
          
        </CardContent>
      </Card>
    </div>
  );
}

export default withPasswordContext(ResetPassword)