import { gql } from '@apollo/client';


export const SEND_PASSWORD_RESET_EMAIL = gql`
	mutation($email: String!) {
		initiatePasswordReset(email: $email)
	}
`;


export const RESET_PASSWORD = gql`
  mutation($input: ChangePasswordInput!) {
    changeAdminPassword(input: $input)
  }
`;


export const SEND_INVITE = gql`
  mutation($adminId: Int!) {
    sendInvite(adminId: $adminId) 
  }
`;

export const ACCEPT_INVITATION = gql`
	mutation($token: String!) {
		acceptInvitation(token: $token) {
			code
			message
		}
	}
`;