import React, { useState, useEffect } from "react";
import MapGL, { Marker, NavigationControl } from "react-map-gl";
import Loading from "../Loading";
import "../../styles/report.css";
import FarmerDefault from "../../assets/images/defaultFarmerImage.png";
import ProductDefault from "../../assets/images/ProductDefaultImage.png";
import Butterpaper from "../../assets/images/butter-search.png";
// import Ok from "../../assets/images/Group764.png";
// import ri from "../../assets/images/rightIcon.svg";
// import instaMart from "../../assets/images/Instamartlogo.png";
// import markerImage from "../../assets/images/marker.webp";
// import okMarker from "../../assets/images/okMarker.png";
import axios from "axios";
import { useParams } from "react-router-dom";
import moment from "moment";
// import { Divider } from "@mui/material";
const MAPBOX_TOKEN = process.env.REACT_APP_MAPBOX_TOKEN;
const API_URL = process.env.REACT_APP_BASE_URL;

function TraceHarvestReport() {
  const { token } = useParams();
  const [report, setReport] = useState(null);
  const { Farmer, Product, harvestTime, Organization } = report ? report : {};
  const [viewport, setViewport] = useState({
    latitude: 28.64144673440961,
    longitude: 77.2139376570681,
    zoom: 11,
  });

  useEffect(() => {
    axios.get(`${API_URL}/api/harvestReport/view/${token}`).then((response) => {
      setReport(response.data);
      document.title = response.data?.Organization?.name;
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (report?.Farmer) {
      setViewport({
        latitude: Number(Farmer.latitude),
        longitude: Number(Farmer.longitude),
        zoom: 11,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [report]);

  let data = [];
  let data1 = {};
  let mapType = { mapStyle: "mapbox://styles/mapbox/streets-v11" };
  // let markerImg = okMarker;
  // if (report?.Organization.name !== "Organic Kitchen") {
  //   markerImg = markerImage;
  // }

  if (report) {
    if (Farmer?.longitude && Farmer?.latitude) {
      data1 = {
        name: Farmer.name,
        longitude: Farmer.longitude,
        latitude: Farmer.latitude,
        img: Farmer?.image ? Farmer.image : FarmerDefault,
        content: `<b>${Farmer.village}</b> ${Farmer.District.name}  `,
        marker: true,
        tag: Farmer?.tag,
        redirectLink: report?.redirectLink,
        LocationCenterProduct: report?.LocationCenterProduct,
      };

      data.push({
        name: Product.name,
        longitude: Farmer.longitude,
        latitude: Number(Farmer.latitude) + 0.025,
        img: Product?.image ? Product.image : ProductDefault,
        content: (
          <pre style={{ margin: 0 }}>{`${
            Farmer?.tag === "market" ? "purchased" : "harvested"
          } here${
            report?.Organization?.name === "Swiggy"
              ? "."
              : ` at ${moment(harvestTime).format("hh:mm A DD/MM/YYYY")}`
          }`}</pre>
        ),
        marker: false,
        additionalInfo: report?.additionalInfo,
        fertilizer: report?.fertilizer,
        pestControl: report?.pestControl,
        redirectLink: report?.redirectLink,
      });
    }
  }

  return report?.Farmer ? (
    <div className="fixed" id="report">
      <div
        className="absolute"
        style={{
          top: 0,
          left: 0,
          zIndex: 12,
          width: "100%",
          // backgroundColor: 'rgba(0, 0, 0, 0.2)',
        }}
      >
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            margin: "10px",
          }}
        >
          <img
            src={Organization.logo}
            // src={instaMart}
            style={{ height: "45px", margin: 10 }}
            alt="Organization Logo"
          />
          <img
            src={Butterpaper}
            style={{ height: "45px", margin: 10 }}
            alt="Organization Logo"
          />
        </div>
      </div>
      <div
        className="absolute"
        style={{
          bottom: data1?.LocationCenterProduct?.Agency?.logo ? 135 : 30,
          left: "50%",
          transform: "translate(-40%, 0%)",
          zIndex: 12,
        }}
      >
        {data1 && data1.tag !== "market" && (
          <div className="flex column middle">
            <div className="farmerLabel">
              <div className="flex">
                {data1.img && (
                  <div
                    style={{
                      position: "absolute",
                      display: "flex",
                      padding: 0,
                      backgroundColor: "#fff",
                      transform: "translate(-45%, 0%)",
                      borderRadius: "100%",
                      bottom: 0,
                    }}
                  >
                    <img
                      style={{
                        display: "flex",
                        width: data1?.LocationCenterProduct?.Agency?.logo
                          ? "80px"
                          : "112px",
                        height: data1?.LocationCenterProduct?.Agency?.logo
                          ? "80px"
                          : "112px",
                        borderRadius: "100%",
                        filter: "drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25))",
                      }}
                      className="img"
                      src={data1.img}
                      alt={data1.name}
                      width="100%"
                    />
                  </div>
                )}
                <div
                  className="content1"
                  style={{
                    padding: "0 10px 0 60px",
                    height: 60,
                    display: "flex",
                  }}
                >
                  <div style={{ margin: "auto", width: "100%" }}>
                    <div
                      className="title"
                      style={{
                        whiteSpace: "nowrap",
                        overflow: "hidden",
                        textOverflow: "ellipsis",
                      }}
                    >
                      <b>{data1.name}</b>
                    </div>
                    <div
                      style={{
                        whiteSpace: "nowrap",
                        overflow: "hidden",
                        textOverflow: "ellipsis",
                      }}
                      dangerouslySetInnerHTML={{ __html: data1?.content }}
                    ></div>
                    {data1.redirectLink && (
                      <div
                        style={{
                          fontSize: 12,
                          textAlign: "end",
                          margin: 0,
                          paddingRight: 3,
                          color: "#000",
                        }}
                      >
                        <a
                          rel="noreferrer"
                          href={data1.redirectLink}
                          target="_blank"
                          style={{
                            fontWeight: "700",
                            color: "#000",
                          }}
                        >
                          See More
                        </a>
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
      <div
        className="absolute"
        style={{
          bottom: 30,
          left: "50%",
          transform: "translate(-56%, 0%)",
          zIndex: 12,
        }}
      >
        {data1 &&
          data1.tag !== "market" &&
          data1?.LocationCenterProduct?.Agency?.logo && (
            <div className="flex column middle">
              <div className="farmerLabel" style={{ width: "232px" }}>
                <div
                  style={{
                    position: "absolute",
                    display: "flex",
                    padding: 0,
                    backgroundColor: "#fff",
                    transform: "translate(54%, 0%)",
                    borderRadius: "100%",
                    bottom: 0,
                    right: 0,
                  }}
                >
                  <img
                    style={{
                      display: "flex",
                      width: "80px",
                      height: "80px",
                      padding: "0.5rem",
                      borderRadius: "100%",
                      filter: "drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25))",
                    }}
                    className="img"
                    src={data1?.LocationCenterProduct?.Agency?.logo}
                    alt="logo"
                    width="100%"
                  />
                </div>
                <div
                  className="content1"
                  style={{
                    padding: "0 24px 0 10px",
                    height: 60,
                    display: "flex",
                  }}
                >
                  <div style={{ margin: "auto", width: "100%" }}>
                    <div
                      className="title"
                      style={{
                        whiteSpace: "nowrap",
                        overflow: "hidden",
                        textOverflow: "ellipsis",
                      }}
                    >
                      <b>Certified Organic</b>
                    </div>
                    <div
                      className="title"
                      style={{
                        whiteSpace: "nowrap",
                        overflow: "hidden",
                        textOverflow: "ellipsis",
                        fontSize: 11,
                      }}
                    >
                      {data1?.LocationCenterProduct?.certificationNo}
                    </div>
                    {data1?.LocationCenterProduct?.certificate && (
                      <div
                        style={{
                          fontSize: 11,
                          textAlign: "start",
                          margin: 0,
                          paddingRight: 3,
                          color: "#000",
                        }}
                      >
                        <a
                          rel="noreferrer"
                          href={data1?.LocationCenterProduct?.certificate}
                          target="_blank"
                          style={{
                            fontWeight: "700",
                            color: "#ffffff",
                          }}
                        >
                          Download Certificate
                        </a>
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </div>
          )}
      </div>
      <MapGL
        {...viewport}
        onViewportChange={(viewport) => {
          setViewport(viewport);
        }}
        width="100vw"
        height="100vh"
        mapboxApiAccessToken={MAPBOX_TOKEN}
        center={[viewport.longitude, viewport.latitude]}
        dragPan={false}
        doubleClickZoom={false}
        scrollZoom={false}
        touchRotate={true}
        touchZoom={false}
        {...mapType}
      >
        <NavigationControl
          showCompass={true}
          showZoom={true}
          style={{ position: "fixed", bottom: 30, right: 10 }}
        />
        {data.map((pointer, index) => {
          return (
            <Marker
              key={index}
              latitude={Number(pointer.latitude)}
              longitude={Number(pointer.longitude)}
              // offsetLeft={-10}
              // offsetTop={50}
              className="markerPin"
            >
              {/* {pointer.marker && <img src={markerImg} width={40} alt="pin" />} */}
              <div className="farmer">
                <div className="tipContainer">
                  {/* {pointer.img && (
                    <div
                      style={{
                        display: "flex",
                        position: "relative",
                        overflow: "hidden",
                        height: "66px",
                        borderTopRightRadius: 10,
                        borderTopLeftRadius: 10,
                        padding: 0,
                        backgroundColor: "#fff",
                      }}
                    >
                      <img
                        className="img absolute"
                        // src={pointer.img}
                        src={
                          "https://s3-alpha-sig.figma.com/img/3ebb/9f3a/c4a36fec91210bf7fe51b8d3584c0a4f?Expires=1655078400&Signature=FkMPAK7BZteU0HMgMFcOHwbicnxbE6I6v~n5FFJ04RICmMBThv38L8Z5Xw4yLnbPfxRlOtjI7TXV1pzqL3KVBjoOa0HXiqoDOWQHsllRm1u0axoSMHiVLG0yFnvC2wqNBNObPpL75IZqsUeQj2WlJXbhhiKxMC4nuE0m3xrA1XoK0j69aLoSTSSxTXfD-md1iEdRnMSwQ5FpC3c6rYq0BBwBRtK3cRohcBUJvV4NxnGOf1x8Kv~FEnlxtRywS8bYr~FHjqdlhpEcCnTORRTqmGmWvDjBTidyub41-ln0lbBVoSPqfhQOgTVjdgXzPmke2BaDGyaLJbPBcW2S9LZSsw__&Key-Pair-Id=APKAINTVSUGEWH5XD5UA"
                        }
                        alt={pointer.name}
                        width="100%"
                      />
                    </div>
                  )} */}
                  {/* {pointer.redirectLink && (
                    <a
                      rel="noreferrer"
                      href={pointer.redirectLink}
                      target="_blank"
                    >
                      <div
                        style={{
                          position: "absolute",
                          width: 18,
                          height: 18,
                          marginTop: -9,
                          right: 9,
                          display: "flex",
                          backgroundColor: "rgba(219, 111, 42, 1)",
                          borderRadius: "100%",
                        }}
                      >
                        <img
                          src={ri}
                          alt={pointer.name}
                          width="100%"
                          style={{ margin: 3 }}
                        />
                      </div>
                    </a>
                  )} */}
                  <div className="content">
                    <div className="title">This {pointer.name} was</div>
                    <div>{pointer.content}</div>
                    <div
                      style={{
                        position: "absolute",
                        color: "#000",
                        transform: "translateX(-50%)",
                        marginTop: "70px",
                        left: "50%",
                        fontSize: 14,
                      }}
                      dangerouslySetInnerHTML={{ __html: data1?.content }}
                    ></div>
                  </div>
                </div>
                {/* {(pointer.additionalInfo ||
                  pointer.fertilizer ||
                  pointer.pestControl) && (
                  <div
                    style={{
                      fontSize: 12,
                      paddingLeft: 10,
                      paddingRight: 10,
                    }}
                  >
                    <Divider style={{ backgroundColor: "#83b325" }} />
                    <div className="flex column">
                      <h>{pointer.additionalInfo}</h>
                      <h>
                        {pointer.fertilizer && (
                          <>{`Crop Fertilizer: ${pointer.fertilizer}`}</>
                        )}
                      </h>
                      <h>
                        {pointer.pestControl && (
                          <>{`Pest Control: ${pointer.pestControl}`}</>
                        )}
                      </h>
                    </div>
                  </div>
                )} */}
                {/* {pointer.redirectLink && (
                  <div
                    style={{
                      fontSize: 12,
                      textAlign: "end",
                      margin: 0,
                      paddingRight: 3,
                    }}
                  >
                    <a
                      rel="noreferrer"
                      href={pointer.redirectLink}
                      target="_blank"
                    >
                      See More
                    </a>
                  </div>
                )} */}
              </div>
            </Marker>
          );
        })}
      </MapGL>
    </div>
  ) : (
    <Loading />
  );
}

export default TraceHarvestReport;
