import axios from "axios";

export const BASE_API_URL = process.env.REACT_APP_BASE_URL;
export const API_URL = `${BASE_API_URL}/api`;

export function getCookies() {
  var x = document.cookie;
  let cookies = x.split(";");
  let data = {};
  cookies.forEach((cookie) => {
    let values = cookie.split("=");
    data[String(values[0]).trim()] = values[1];
  });
  return data;
}

export function retrieveItem(name) {
  return getCookies()[name]
}

export function setCookie(name, value, days = 5) {
  var expires = "";
  if (days) {
    let date = new Date();
    date.setTime(date.getTime() + (days * 24 * 60 * 60 * 1000));
    expires = "; expires=" + date.toUTCString();
  }
  document.cookie = name + "=" + (value || "") + expires + "; path=/";
}

function setRequestAuthHeader(key = "x-admin-token", accessToken) {
  // alert(key + " " + accessToken)
  axios.defaults.headers.common[key] = accessToken;
}

export const adminAccessToken = getCookies()["x-admin-token"];

export function initRequestAuthHeader(key = "x-admin-token") {
  let userAccessToken = getCookies()[key];
  if (userAccessToken) setRequestAuthHeader(key, userAccessToken);
}
if (getCookies()["x-admin-token"]) {
  initRequestAuthHeader("x-admin-token");
} else {
  initRequestAuthHeader("x-driver-token");
}

export function request(config) {
  return axios.request(config);
}


export function postRequest(url, data) {
  return axios.request({
    url: url,
    method: 'post',
    data,
    headers: {
      'Content-Type': 'application/json',
    }
  });
}

export function getRequest(url) {
  return axios.request({
    url: url,
    method: 'get'
  });
}

export function getRequestParam(url, param) {

  return axios.request({
    url: url,
    method: 'get',
    params: param
  });
}

export function dataURItoBlob(dataURI) {
  // convert base64 to raw binary data held in a string
  // doesn't handle URLEncoded DataURIs - see SO answer #6850276 for code that does this
  var byteString = atob(dataURI.split(',')[1]);

  // separate out the mime component
  var mimeString = dataURI.split(',')[0].split(':')[1].split(';')[0];

  // write the bytes of the string to an ArrayBuffer
  var ab = new ArrayBuffer(byteString.length);
  var ia = new Uint8Array(ab);
  for (var i = 0; i < byteString.length; i++) {
    ia[i] = byteString.charCodeAt(i);
  }

  return new Blob([ab], { type: mimeString });
}

export function handleApiErrorMessage(err) {
  console.error("API ERROR DATA", err.response)
  if (!err) {
    throw new Error("No error object provided");
  } else if (err.response && err.response.status) {
    return err.response.status + ' ' + err.response.statusText;
  } else if (err.response && err.response.data) {
    return err.response.data;
  } else if (err.message) {
    return err.message;
  } else if (typeof err === 'string') {
    return err
  }
  console.error(err);
  return "Some error occured.";
}