import React, { Suspense } from 'react';
import { Switch, Route } from "react-router-dom";
import Loading from '../Loading';
import TraceHarvestReport from './TraceHarvestReport';

function TraceRoutes() {
  return (
    <div>
      <Suspense fallback={<Loading />}>
        <Switch>
          <Route 
            path="/trace/harvestReport/:token"
            component={TraceHarvestReport}
          />
        </Switch>
      </Suspense>
    </div>
  );
}

export default TraceRoutes;