import React from 'react';
import ReactDOM from 'react-dom';
import {SnackbarProvider} from 'notistack';
import './index.css';
import App from './App';
import './assets/fonts/OpenSans-Light.ttf'
import reportWebVitals from './reportWebVitals';

ReactDOM.render(
  <React.StrictMode>
    <SnackbarProvider>
      <App />
    </SnackbarProvider>
  </React.StrictMode>,
  document.getElementById('root')
);

reportWebVitals();
