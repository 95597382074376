import * as React from 'react';
import IconButton from '@mui/material/IconButton';
import TextField from '@mui/material/TextField';
import InputAdornment from '@mui/material/InputAdornment';
import FormControl from '@mui/material/FormControl';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';

export default function PasswordInput({value, onChange, label = 'Password', defaultType = 'password', ...props}) {
  const [showPassword, setValues] = React.useState(defaultType === 'text');

  const handleClickShowPassword = () => {
    setValues(!showPassword);
  };

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

	return (
		<FormControl variant="outlined" className='w-100'>
			{/* <InputLabel htmlFor="outlined-adornment-password">{label}</InputLabel> */}
			<TextField
				value={value}
				type={showPassword ? 'text' : 'password'}
				onChange={(e) => onChange(e.target.value)}
				InputProps={{
					endAdornment: (
						<InputAdornment position="end">
							<IconButton
								onClick={handleClickShowPassword}
								onMouseDown={handleMouseDownPassword}
								edge="end"
							>
								{showPassword ? <VisibilityOff /> : <Visibility />}
							</IconButton>
						</InputAdornment>
					)
				}}
				variant='outlined'
				label={label}
				{...props}
			/>
		</FormControl>
	)
}