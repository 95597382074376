import { ApolloClient, InMemoryCache, createHttpLink } from '@apollo/client';
import { setContext } from '@apollo/client/link/context';
import { retrieveItem } from "../helpers/utils";

const BASE_API_URL = process.env.REACT_APP_BASE_URL;
const API_URL = `${BASE_API_URL}/graphql`;

const httpLink = createHttpLink({
  uri: API_URL,
});

const authLink = setContext(async (_, { headers }) => {
  // get the authentication token from local storage if it exists
  const token = await retrieveItem('authorization');
  const organizationid = await retrieveItem('organizationid');
  // return the headers to the context so httpLink can read them
  // alert(token)
  return {
    headers: {
      ...headers,
      authorization: token ? `${token}` : "",
      organizationid: organizationid ? String(organizationid) : "",
    }
  }
});

const cache = new InMemoryCache({
  typePolicies: {
    Query: {
      fields: {
        // myPastOrders: offsetLimitPagination(['search'])
        myPastOrders: {
          // Don't cache separate results based on
          // any of this field's arguments.
          keyArgs: ['search'],
          // Concatenate the incoming list items with
          // the existing list items.
          merge(existing = [], incoming) {
            return [...existing, ...incoming]
            // return mergeDistributorOrders(existing, incoming)
          },
        }
      }
    }
  }
})

// Initialize Apollo Client
const client = new ApolloClient({
  link: authLink.concat(httpLink),
  cache,
});

export default client;
